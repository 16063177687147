<template>
  <div>
    <div
      :style="backgroundStyle"
      class="base-page login-page backgrounded"
    >
      <main-menu />
      <div class="main-content">
        <slot />
      </div>
      <div class="main-footer">
        <public-footer />
      </div>
    </div>
  </div>
</template>
<script>
import MainMenu from "~/components/v2/public/MainMenu";
import PublicFooter from "~/components/general/PublicFooter";
import backgroundUrl from "~/assets/img/background/homepage-background.png";

const bootstrapToggles = function (event) {
  if (event.target.classList.contains("navbar-toggler-icon")) {
    document.getElementById("navbarResponsive").classList.toggle("show");
  } else if (event.target.classList.contains("nav-link")) {
    document.getElementById("navbarResponsive").classList.remove("show");
  }
};

export default {
  name: "PublicLayout",
  components: {
    MainMenu,
    PublicFooter,
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${backgroundUrl})`,
      };
    },
  },
  mounted() {
    // Bootstrap 4 navbar toggles
    document.addEventListener("click", bootstrapToggles);
  },
  unmounted() {
    document.removeEventListener("click", bootstrapToggles);
  },
};
</script>

<style>
.base-page {
  background-color: #f4f5f9;
}
.backgrounded {
  background-position: right bottom;
  background-repeat: no-repeat;
}
</style>
