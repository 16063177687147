<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a
      class="navbar-brand"
      href="/"
    ><img
      :src="logoUrl"
      alt="Toolsquare logo"
      height="30px"
    ></a>
    <button
      aria-controls="navbarResponsive"
      aria-expanded="false"
      aria-label="Toggle navigation"
      class="navbar-toggler"
      data-target="#navbarResponsive"
      data-toggle="collapse"
      type="button"
    >
      <span class="navbar-toggler-icon" />
    </button>
    <div
      id="navbarResponsive"
      class="collapse navbar-collapse"
    >
      <ul class="navbar-nav ml-auto mt-2 mt-lg-0 align-items-center">
        <li class="nav-item">
          <a
            class="p-2 text-dark"
            href="https://www.toolsquare.io/how-it-works"
            rel="noopener"
            target="_blank"
          >how it works</a>
        </li>
        <li class="nav-item">
          <a
            class="p-2 text-dark"
            href="https://www.toolsquare.io/about-us"
            rel="noopener"
            target="_blank"
          >about us</a>
        </li>
        <li class="nav-item">
          <a
            class="p-2 text-dark"
            href="https://www.toolsquare.io/cases"
            rel="noopener"
            target="_blank"
          >cases</a>
        </li>
        <li class="nav-item">
          <a
            class="p-2 text-dark active"
            href="https://www.toolsquare.io/insights/blog"
            rel="noopener"
            target="_blank"
          >insights</a>
        </li>
        <li class="nav-item">
          <a
            class="btn btn-primary ml-2"
            href="/login"
          >Log in</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import logoUrl from "~/assets/img/toolsquare-logo.svg";

export default {
  name: "MainMenu",
  data() {
    return {
      logoUrl,
    };
  },
};
</script>

<style scoped>
.navbar {
  padding: 0.625rem 57px;
}
</style>
